import { Text, Html, ContactShadows, PresentationControls, Environment, useGLTF, OrbitControls, Float } from '@react-three/drei'


export default function Experience()
{
    const computer = useGLTF('../container-with-lid-01.glb')

    return <>

        <Environment preset="studio" />

        <color args={ [ '#241a1a' ] } attach='background' />
        <OrbitControls
            minAzimuthAngle={-Math.PI / 1.2}
            maxAzimuthAngle={Math.PI / 1.2}
            minPolarAngle={Math.PI / 6}
            maxPolarAngle={Math.PI - Math.PI / 6}
            minDistance={2}
            maxDistance={5}
        />
        
        <rectAreaLight
                    width={ 2.5 }
                    height={ 1.65 }
                    intensity={ 1 }
                    color={ '#ff6900' }
                    rotation={ [ -0.1, Math.PI, 0 ] }
                    position={ [ 0, 0.55, -1.15 ] }
                />
                <primitive
                    object={ computer.scene }
                    position-y={ -0.2 }
                    scale={8}
                />
            
            <ContactShadows
            position-y={ -0.1 }
            opacity={ 0.4 }
            scale={ 5 }
            blur={ 2.4 }
        />

    </>
}